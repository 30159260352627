import React from 'react'
import Header from '../Header/Header'
import '../Hero/Hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'


const Hero = () => {

const transition = {type: 'spring' , duration: 3};
const mobile = window.innerWidth<=768 ? true: false;

  return (
    <div className='hero' id='hero'>
      <div className='blur blur-h'></div>
      <div className='left-h'>
        <Header />

        {/* the best ad */}

        
        <div className="the-best-ad">
      

          <motion.div
          
          initial={{left: mobile?'178px' : '238px'}}
            whileInView={{left: '8px'}}
            transition={{...transition, type: 'tween'}}
          >  
          </motion.div>
          <span>The best fitness club in the town</span>
        </div>

        {/* hero heading */}
        <div className="hero-text">

          <div>

            <span className='stroke-text'>Shap </span>
            <span>Your</span>

          </div>

          <div>
            <span>Ideal Body </span>
          </div>

          <div>
            <span>In here we will help you to shape and build your ideal body shap and live up your life to fullest</span>
          </div>
        </div>

        {/* figures */}

        <div className="figures">
          <div>
            <span>
              <NumberCounter end={140} start={80} delay='4' preFix="+"/>
               </span>
            <span>expert coaches</span>
          </div>

          <div>
            <span>
            <NumberCounter end={978} start={780} delay='4' preFix="+"/></span>
            <span>members joined</span>
          </div>

          <div>
            <span>
            <NumberCounter end={50} start={26} delay='4' preFix="+"/></span>
            <span>fitness programs</span>
          </div>

        </div>
        <div className="hero-button">

          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>

      </div>
      <div className='right-h'>

        <button className='btn'>Join Us</button>

        <motion.div 
        initial={{right:'-1rem'}}
        whileInView={{right:'4rem'}}
        transition={transition}
        className="heart-rate">
          <img src={heart} alt='heart' />
          <span>Heart Rate</span>
          <span>
          <NumberCounter end={116} start={90} delay='4'postFix="bpm"/>  </span>
        </motion.div>


        {/* hero image */}
        <motion.img 
        initial={{right:"16rem"}}
        whileInView={{right:'23rem'}}
        transition={transition}
        src={hero_image_back} alt='hero' className="hero-img-back" />
        <img src={hero_image} alt='hero' className="hero-img" />

        {/* calories */}

        <motion.div 
        initial={{right:'37rem'}}
        whileInView={{right:"28rem"}}
         transition={transition}
        className="calories">
          <img src={calories} alt='calories'/>
          <div>

          <span>calories Burned</span><span><NumberCounter end={220} start={190} delay='4'postFix="kcal"/> </span>
          
          </div>

        </motion.div>
        
      </div>

    </div>
  )
}

export default Hero
