import React from 'react'
import './Plans.css'
import { plansData } from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
    return (
        <div className='plans-container' id='plans1'>
            <div className="blur blur-p1"></div>
            <div className="blur blur-p2"></div>
            <div className="plans-header">
                <span className='stroke-text'>READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className='stroke-text'>NOW WITH US</span>
            </div>

            <div className="plans">
                {plansData.map((plan, i) => (
                    <div className="plan" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>$ {plan.price}</span>
                        <div className="features">
                            {plan.features.map((feature, i) => (
                                <div className='feature'>
                                    <img src={whiteTick} alt="" />
                                    <span key={i}>{feature}</span>
                                </div>

                            ))}

                        </div>
                        <div>
                            <span  style={{paddingbottom:'4rem'}}>See more benefits -></span>
                            <button className="btn btn1">Join now</button>
                            </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Plans
